export const PER_PAGE_RECORD = 10;

export const USERS_DATA = [
  {
    id: 1,
    data: 'User From All Organizational Units',
  },
  {
    id: 2,
    data: 'User From Selected Organizational Units',
  },
];

export const USERS_DROPDOWN_DATA = [
  {
    id: 1,
    heading: 'Company Account',
    show: true,
    subHeading: [
      {
        id: 11,
        title: 'Company Browers',
      },
    ],
  },
  {
    id: 2,
    heading: 'Company Devices',
    show: true,
    subHeading: [
      {
        id: 21,
        title: 'Deprovisioned',
      },
      {
        id: 22,
        title: 'Employee Devices',
      },
    ],
  },
  {
    id: 3,
    heading: 'Greenvile',
    show: true,
    subHeading: [],
  },
  {
    id: 4,
    heading: 'HQ',
    show: true,
    subHeading: [
      {
        id: 41,
        title: 'Manage Guest',
      },
      {
        id: 42,
        title: 'New Devices',
      },
      {
        id: 43,
        title: 'Office Use Devices',
      },
      {
        id: 44,
        title: 'Test Devices',
      },
      {
        id: 45,
        title: 'Tradeshow Devices',
      },
    ],
  },
];

export const POPUP_DATA = [
  {
    id: 1,
    data: 'Active',
  },
  {
    id: 2,
    data: 'In Active',
  },
];
export const USERS_FILTER_FIELDS = [
  { id: 1, label: 'Full Name', value: 'full_name' },
  { id: 2, label: 'Role', value: 'role_name' },
  { id: 4, label: 'Phone', value: 'phone' },
];
export const USERS_TABLEHEAD = [
  { id: 1, title: 'Name' },
  { id: 2, title: 'Email' },
  { id: 3, title: 'Status' },
  { id: 4, title: 'Phone' },
  { id: 5, title: 'Cell Number' },
  { id: 6, title: 'Role' },
  { id: 7, title: 'Action' },
];

export const USERS_ADMIN = [
  { id: 1, title: 'Account Name' },
  { id: 2, title: 'Request Date' },
  { id: 3, title: 'Subscription Tier Request' },
  { id: 4, title: 'Request User' },
  { id: 5, title: 'Notes' },
  { id: 6, title: 'Action' },
];

export const WARRANTY = [
  { id: 1, title: 'Name' },
  { id: 2, title: 'Provider' },
  { id: 3, title: 'SKU' },
  { id: 4, title: 'Type' },
  { id: 5, title: 'Action' },
];

export const NEW_USER = [
  { id: 1, title: 'Email' },
  { id: 2, title: 'Auth Type' },
  { id: 3, title: 'Status' },
  { id: 4, title: 'User Type' },
  { id: 5, title: 'requested Date' },
  { id: 6, title: 'Action' },
];

export const NEW_IMPORTS = [
  { id: 1, title: 'User name' },
  { id: 2, title: 'Request date' },
  { id: 3, title: 'Status' },
  { id: 4, title: 'Quantity Devices' },
  { id: 5, title: 'Download File' },
  { id: 6, title: 'Action' },
];

export const WARRANTY_TEMPLATE = {
  serial_number: '',
  building: '',
  import_number: null,
  device: '',
  asset_tag: '',
  student: '',
  loaner_status: '',
  device_status: '',
};

export const WARRANTY_REQUIRED_HEADERS = [
  { id: 1, title: '*Serial Number', key: 'serial_number' },
  { id: 2, title: 'Account Domain', key: 'account_number' },
  { id: 3, title: '*SKU', key: 'SKU' },
  { id: 4, title: 'Asset Tag', key: 'asset_tag' },
  { id: 5, title: '*Base SKU', key: 'base_SKU' },
  { id: 6, title: 'Start Date', key: 'start_date' },
  { id: 7, title: 'Base End Date', key: 'base_end_date' },
  { id: 8, title: '*Advanced SKU', key: 'advanced_SKU' },
  { id: 9, title: 'Advanced Start Date', key: 'advanced_start_date' },
  { id: 10, title: 'Advanced End Date', key: 'advanced_end_date' },
  { id: 11, title: 'Building Dream ID', key: 'building' },
];

export const WARRANTY_REQUIRED_ADMIN_HEADERS = [
  { id: 1, title: 'Name', key: 'warranty_name' },
  { id: 2, title: 'SKU', key: 'sku' },
  { id: 3, title: 'Type', key: 'warranty_type' },
  { id: 4, title: 'Damage Protection', key: 'damage_protection' },
  { id: 5, title: 'Device Price', key: 'price_per_device' },
  { id: 6, title: 'Years', key: 'years' },
  { id: 7, title: 'Months', key: 'months' },
  { id: 8, title: 'Battery Months', key: 'battery_month' },
  { id: 9, title: 'Battery Year', key: 'battery_year' },
  { id: 10, title: 'Priority', key: 'priority' },
];

export const TABLE = [
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
  { id: 5, title: 'E' },
  { id: 6, title: 'F' },
  { id: 7, title: 'G' },
  { id: 8, title: 'H' },
  { id: 9, title: 'I' },
  { id: 10, title: 'J' },
  { id: 11, title: 'K' },
];

export const ADMIN_TABLE = [
  { id: 1, title: 'A' },
  { id: 2, title: 'B' },
  { id: 3, title: 'C' },
  { id: 4, title: 'D' },
  { id: 5, title: 'E' },
  { id: 6, title: 'F' },
  { id: 7, title: 'G' },
  { id: 8, title: 'H' },
  { id: 9, title: 'I' },
  { id: 10, title: 'K' },
];

export const USERS_REQUIRED_HEADERS = [
  { id: 1, title: 'Full Name', key: 'full_name' },
  { id: 1, title: 'Title', key: 'title' },
  { id: 2, title: 'Email', key: 'email' },
  { id: 3, title: 'Phone', key: 'phone' },
  { id: 4, title: 'Account', key: 'account' },
  { id: 5, title: 'User Role', key: 'user_role' },
  { id: 6, title: 'Building', key: 'building' },
  { id: 7, title: 'User Status', key: 'user_status' },
];

export const USERS_REQUIRED_HEADERS_BULK_UPDATE = [
  { id: 1, title: 'Full Name', key: 'full_name' },
  { id: 1, title: 'Title', key: 'title' },
  { id: 2, title: 'Email', key: 'email' },
  { id: 3, title: 'Phone', key: 'phone' },
  { id: 4, title: 'User Role', key: 'user_role' },
  { id: 5, title: 'Building', key: 'building' },
  { id: 6, title: 'User Status', key: 'user_status' },
];

export const WARRANTY_TABLEDATA = [
  {
    serial_number: '1234AC',
    account_number: 'test123.org',
    SKU: '6AB19UT-NW',
    asset_tag: '673TY',
    base_SKU: '1YRABCWAR',
    start_date: '01/16/2024',
    base_end_date: '02/16/2024',
    advanced_SKU: 'AB990E',
    building: 1,
    advanced_start_date: '01/16/2024',
    advanced_end_date: '02/16/2024',
  },
  {
    serial_number: '1234AC',
    account_number: 'test123.org',
    SKU: '6AB19UT-NW',
    asset_tag: '673TY',
    building: 1,
    base_SKU: '1YRABCWAR',
    start_date: '01/16/2024',
    base_end_date: '02/16/2024',
    advanced_SKU: 'AB990E',
    advanced_start_date: '01/16/2024',
    advanced_end_date: '02/16/2024',
  },
];

export const WARRANTY_ADMIN_TABLEDATA = [
  {
    sku: 'U7925E',
    warranty_type: 'Advanced',
    warranty_description: '',
    damage_protection: 'Mechanical',
    price_per_device: null,
    years: 5,
    months: 60,
    battery_year: 5,
    battery_month: 59,
    warranty_name: 'HP 5-Year',
  },
  {
    sku: 'U17XSE',
    warranty_type: 'Advanced',
    warranty_description: '',
    damage_protection: 'Mechanical',
    price_per_device: null,
    years: 4,
    months: 48,
    battery_year: 4,
    battery_month: 48,
    warranty_name: 'HP 4-Year Care Pack',
  },
  {
    sku: 'UQ990E',
    warranty_type: 'Advanced',
    warranty_description: '',
    damage_protection: 'Mechanical',
    price_per_device: null,
    years: 3,
    months: 36,
    battery_year: 3,
    battery_month: 36,
    warranty_name: 'HP 3-Year Pickup',
  },
];
export const USERS_TABLEDATA: Record<string, any>[] = [
  {
    id: 1,
    full_name: 'John Doe',
    email: 'johndoe142@vivacitytech.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
    building: 1,
    title: 'MR',
  },
  {
    id: 2,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 3,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 4,
    full_name: 'John Doe',
    email: 'johndoe142@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 5,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 6,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 7,
    full_name: 'John Doe',
    email: 'johndoe142@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 8,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 9,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 10,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 11,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 12,
    full_name: 'Max Deep',
    email: 'maxdeep154@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
  {
    id: 13,
    full_name: 'John Doe',
    email: 'johndoe142@vivecity.com',
    phone: '(555) 435-2332',
    user_status: 'Active',
    account: 1,
    user_role: 1,
  },
];

export const USER_TEMPLATE = {
  full_name: '',
  title: '',
  email: '',
  phone: '',
  user_status: '',
  user_role: null,
  building: null,
};

export const USER_EMAIL_PREFERENCES = ['in_house_repair'];

export const FILTER_FIELDS_USER = [
  {
    id: 1,
    label: 'Full Name',
    value: 'full_name',
  },
  {
    id: 2,
    label: 'Email',
    value: 'email',
  },
  {
    id: 3,
    label: 'Phone',
    value: 'phone',
  },
  {
    id: 4,
    label: 'Role',
    value: 'role_name',
  },
];
