import React from 'react';
import { Button, styled, Tooltip } from '@mui/material';
import { setWith } from 'lodash';
import COLORS from 'src/constants/colors/colors.constant';

interface PrimaryButtonProps {
  tooltip?: string;
  [key: string]: any;
}

export const PrimaryButtonCore = styled(Button)(({ theme }) => {
  setWith(theme, 'palette.primary.main', COLORS.primary.main);
  setWith(theme, 'palette.getContrastText', () => COLORS.primary.main);
  return {
    fontWeight: 'bold',
    padding: '8px 16px',
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.spacing(1),
    textTransform: 'none',
    gap: theme.spacing(1),
    '&.MuiButton-root:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    '&.MuiButton-root:disabled': {
      color: COLORS.primary.offWhite,
      backgroundColor: COLORS.primary.disabledGrey,
      pointerEvents: 'auto',
    },
  };
});

const PrimaryButton: React.FC<PrimaryButtonProps> = ({ tooltip, ...props }) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <PrimaryButtonCore {...props} />
    </Tooltip>
  ) : (
    <PrimaryButtonCore {...props} />
  );

export default PrimaryButton;
