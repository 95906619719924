import React, { useState } from 'react';
import { useTheme, Typography, IconButton, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { setCookie, getCookie } from '../../utils/web.util';
import topAppBarStyles from './topAppBar.styles';
import TOPBAR from '../../constants/data/topAppBar.constants';

interface TopAppBarProps {
  content: string;
}

function TopAppBar({ content }: TopAppBarProps) {
  // Set the cookie with a 72-hour expiration
  const cookieName = TOPBAR?.cookieName;
  const expirationTime = TOPBAR?.expirationTime; // hours
  const cookieValue = getCookie(cookieName);
  const cookieStatus = cookieValue === '' ? true : /true/.test(cookieValue);

  const [isAppBarOpen, setIsAppBarOpen] = useState(cookieStatus);
  const theme = useTheme();
  const styles = topAppBarStyles(theme);

  const onClose = () => {
    setIsAppBarOpen(false);
    setCookie(cookieName, false, expirationTime);
  };

  const transformContentToHyperlink = (text: string) => {
    const regex = /(https:\/\/[^\s]+)/g;
    const match = regex.exec(text);
    if (match) {
      const url = match[0];
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            fontWeight: 'bold',
            textDecoration: 'underline',
            color: 'inherit',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {text.replace(url, '').trim()}
          <OpenInNewIcon sx={{ ml: 0.5, fontSize: 'inherit' }} />
        </a>
      );
    }
    return text;
  };

  return isAppBarOpen ? (
    <Box sx={styles.customizedTopBar} display="flex" alignItems="center" justifyContent="space-between" padding={2}>
      <Typography component="div" sx={styles.customizedTopBarTypography}>
        {transformContentToHyperlink(content)}
      </Typography>
      <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
        <CloseIcon />
      </IconButton>
    </Box>
  ) : null;
}

export default TopAppBar;
