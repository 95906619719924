import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Navigate } from 'react-router-dom';
import { getBooleanFeatureFlagValue } from 'src/utils/LaunchDarklyQuery';
import useRoleIdentity, { PAGE_NAMES } from '../roles/hooks';
import { FORBIDDEN_ACCESS } from '../../constants/routes';

type Props = {
  name?: string | null;
  children: JSX.Element;
};

const ProtectedRoute = ({ name = null, children }: Props) => {
  const { isAuthenticated, isLoading, loginWithRedirect } = useAuth0();
  const identityCheck = useRoleIdentity(name);
  const [ldTransactionView, setLdTransactionView] = useState<boolean | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const transactionView = await getBooleanFeatureFlagValue('dream-feature-transactions');
        setLdTransactionView(transactionView);
      } catch (error) {
        setLdTransactionView(false);
      }
    };
    fetchData();
  }, []);

  if (isLoading || ldTransactionView === null) return null;

  if (!isAuthenticated) {
    loginWithRedirect();
    return null;
  }

  if (name === PAGE_NAMES.TRANSACTIONS && !ldTransactionView) return <Navigate to={FORBIDDEN_ACCESS} />;

  if (name && isAuthenticated && !identityCheck.canViewPage()) return <Navigate to={FORBIDDEN_ACCESS} />;

  return children;
};

export default ProtectedRoute;
