import COLORS from '../../constants/colors/colors.constant';

const topAppBarStyles = (theme: any) => ({
  customizedTopBar: {
    color: COLORS.primary.textDark, // Ensures text color is applied from this setting
    backgroundColor: COLORS.primary.main,
    '& > .MuiToolbar-root': {
      minHeight: theme.spacing(6.25),
      // Target anchor tags specifically within the toolbar
      '& a': {
        fontWeight: 'bold', // Make text bold
        textDecoration: 'underline', // Underline text
        color: 'inherit', // Inherit color from parent, ensuring it matches other text
      },
    },
    '& > div': {
      textAlign: 'center',
      justifyContent: 'center',
    },
    minHeight: '50px',
    maxHeight: '50px',
    zIndex: theme.zIndex.drawer + 2,
    position: 'relative',
  },
  customizedTopBarTypography: {
    flexGrow: 1,
  },
  spoofTopBar: {
    position: 'fixed',
    zIndex: 1,
    color: COLORS.primary.textDark,
    backgroundColor: COLORS.dashColors.red2,
    '& > .MuiToolbar-root': {
      minHeight: theme.spacing(6.25),
      '& a': {
        fontWeight: 'bold', // Assuming you also want this style in spoofTopBar
        textDecoration: 'underline',
        color: 'inherit',
      },
    },
    '& > div': {
      textAlign: 'center',
      justifyContent: 'center',
    },
  },
  popupContainer: {
    width: '100%',
  },
  clickHere: {
    fontSize: theme.spacing(2),
    cursor: 'pointer',
    fontWeight: 'bold',
    color: 'inherit',
    textTransform: 'none',
    marginBottom: theme.spacing(0.2),
    marginLeft: theme.spacing(-0.45),
    marginRight: theme.spacing(-0.45),
  },
});

export default topAppBarStyles;
