import React from 'react';
import { Button, styled, Tooltip } from '@mui/material';
import COLORS from 'src/constants/colors/colors.constant';

interface SecondaryButtonProps {
  disabledStyle?: boolean;
  disabledCompletely?: boolean;
  tooltip?: string;
  [key: string]: any;
}

const SecondaryButtonCore = styled(Button)<SecondaryButtonProps>(({ theme, disabledStyle, disabledCompletely }) => ({
  fontWeight: 'bold',
  padding: '8px 16px',
  color: disabledStyle ? COLORS.primary.disabledGrey : theme.palette.primary.main,
  backgroundColor: disabledStyle ? COLORS.primary.disabledGrey : COLORS.primary.palePurple,
  borderRadius: theme.spacing(1),
  textTransform: 'none',
  pointerEvents: disabledCompletely ? 'none' : 'auto',
  opacity: disabledCompletely ? 0.6 : 1,
  gap: theme.spacing(1),
  '&:hover': {
    backgroundColor: disabledStyle ? COLORS.primary.disabledGrey : COLORS.primary.lilac,
  },
}));

const SecondaryButton: React.FC<SecondaryButtonProps> = ({ tooltip, ...props }) =>
  tooltip ? (
    <Tooltip title={tooltip}>
      <SecondaryButtonCore {...props} />
    </Tooltip>
  ) : (
    <SecondaryButtonCore {...props} />
  );

export default SecondaryButton;
